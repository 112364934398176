import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Lazy from 'yup/lib/Lazy';

// dashboard routing    
const Projects = Loadable(lazy(() => import('views/Projects/Projects')));
const RetrosynthesisOutput = Loadable(lazy(() => import('views/Rebolt/Retrosynthesis/RetrosynthesisOutput')));
const ForwardReactionOutput = Loadable(lazy(() => import('views/Rebolt/ForwardReaction/ForwardReactionOutput')));
const Accountdetails = Loadable(lazy(() => import('views/Accountdetails')));
const Experiments = Loadable(lazy(() => import('views/Projects/Experiments')));
const SharedExperiments = Loadable(lazy(() => import('views/Projects/SharedExperiments')));
// const TemplateFree = Loadable(lazy(() => import('views/Rebolt/Retrosynthesis/templateFree')));
// const TemplateFreeTree = Loadable(lazy(() => import('views/Rebolt/Retrosynthesis/templatefreetree')));
const Help = Loadable(lazy(() => import('views/help/help')));
const Library = Loadable(lazy(() => import('views/library/index')));
const Analogslibrary = Loadable(lazy(() => import('views/analogslibrary/index')));
const Collaborations = Loadable(lazy(() => import('views/collaborations/index')));
const Experimentroot = Loadable(lazy(() => import('views/Rebolt/Experimentsroot/ExperimentRoot')));
const Faq=Loadable(lazy(()=>import('views/help/help')));
const D3tree = Loadable(lazy(() => import('views/Rebolt/Retrosynthesis/D3tree')));
const ForwardReactionInput =Loadable(lazy(() => import('views/Rebolt/ForwardReaction/ForwardReactionInput')));
const ConditionRecomendationInput =Loadable(lazy(() => import('views/Rebolt/ConditionRecommendations/ConditionRecomendationInput')));
const ConditionRecomendationOutput =Loadable(lazy(() => import('views/Rebolt/ConditionRecommendations/ConditionRecomendationOutput')));
const ImpurityPredictionInput =Loadable(lazy(() => import('views/Rebolt/ImpurityPrediction/impurityPredictionInput')));
const ImpurityPredictionOutput =Loadable(lazy(() => import('views/Rebolt/ImpurityPrediction/impurityPredictionOutput')));
// const ExampleCanvas = Loadable(lazy(() => import('views/RDkit/ExampleCanvas')));

{/* input data routes */}
// const Retrosynthesis_input_data=Loadable(lazy(()=>import('views/experiment_data/Retrosynthesis_input_data')));
// const Retrosynthesis_tf_data=Loadable(lazy(()=>import('views/experiment_data/Retrosynthesis_tf_data')));
// const Forwardreaction_data=Loadable(lazy(()=>import('views/experiment_data/Forward_reaction_data')));
const Copy=Loadable(lazy(()=>import('views/copy/Copy')));
const Copysoutput=Loadable(lazy(()=>import('views/copy/Copysoutput')));
const Testcard=Loadable(lazy(()=>import('views/testcard/Testcard')));
const TemplatefreeCopysoutput=Loadable(lazy(()=>import('views/copy/Templatefreecopyoutput')));
const SMLIBARY = Loadable(lazy(()=>import('views/Projects/library')))
const AtomMappingIn = Loadable(lazy(()=>import('views/Rebolt/AtomMapping/AtommappingInput')))
const AtommappingOutput = Loadable(lazy(()=>import('views/Rebolt/AtomMapping/Atommappingoutput')))
const Dashboard = Loadable(lazy(()=>import('views/pages/authentication/Dashboard/dashboard')))
const Iboutput = Loadable(lazy(()=>import('views/Rebolt/InteractiveBuilder/IBoutput/iboutputs')))
const Ibinput=Loadable(lazy(()=>import('views/Rebolt/InteractiveBuilder/IBinput/ibinput')))
const Custommodels = Loadable(lazy(()=>import('views/Rebolt/Modelfine')))



// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard/>
        },
        {
            path: '/experiments',
            element: <Experimentroot />
        },
        {
            path: '/help',
            element: <Help />
        },
        {
            path: '/Projects',
            element: <Projects />
        },
        {
            path: '/Experiments/:pid',
            element: <Experiments />
        },
        {
            path: '/Rebolt/:template/:pid/:eid',
            element: <RetrosynthesisOutput />
        }
        ,
        {
            path: '/forward_reaction/:eid',
            element: <ForwardReactionOutput />
        },
        {
            path: '/Rebolt/Experimentsroot',
            element: <Experimentroot />
        },
        {
            path: '/Accountdetails',
            element: <Accountdetails />
        },
        
        {
            path: '/collaborations',
            element: <Collaborations />
        }
        ,
        {
            path: '/Faq/Faq',
            element: <Faq />
        }
        ,
        {
            path: '/SharedExperiments',
            element:<SharedExperiments/>
        }
        ,
        {
            path: '/Rebolt/d3tree/:template/:pid/:eid/:top/:idx',
            element: <D3tree />
        }
        ,
        {
            path: '/library',
            element: <Library />
        },
        {
            path: '/Analogslibrary',
            element: <Analogslibrary />
        },
        {
            path: '/Copies',
            element: <Copy />
        },
        {
            path: '/Copiesoutput',
            element: <Copysoutput />
        },
        {
            path: '/TemplatefreeCopiesoutput',
            element: <TemplatefreeCopysoutput />
        },
        {
            path: '/test',
            element: <Testcard />
        },
        {
            path:'/ForwardReactionInput',
            element:<ForwardReactionInput/>
        },
        {
            path:'/ConditionsRecommendationIn',
            element:<ConditionRecomendationInput/>
        },
        {
            path:'/condition_recommendation/:eid',
            element:<ConditionRecomendationOutput/>
        },
        {  
           path:'/ImpurityPredictionIn',
           element:<ImpurityPredictionInput/>
        },
        {
            path:'/impurities/:eid',
            element:<ImpurityPredictionOutput/>
        },
        {
            path:'/SMLibrary',
            element:<SMLIBARY/>
        },
        {
            path:'/AtomMappingIn',
            element:<AtomMappingIn/>
        },
        {
            path:'/atommapping/:eid',
            element:<AtommappingOutput/>
        },
        {
            path:'/path_builder/:eid',
            element:<Iboutput/>
        },
        {
            path:'/ibinput',
            element:<Ibinput/>
        },
        {
            path:'/custommodels',
            element:<Custommodels/>
        }
        
        ]
};

export default MainRoutes;
