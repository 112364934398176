import { Alert, AlertTitle, Button, Card, Divider, Grid, Typography, useMediaQuery,Paper } from '@mui/material'
import { Box } from '@mui/system'
import { signinwithgoogle } from 'index'
import Customization from 'layout/Customization'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import theme from 'themes'
import { useTheme } from '@mui/styles'
import MainCard from 'ui-component/cards/MainCard'
import AnimateButton from 'ui-component/extended/AnimateButton'
import image from '../../../../assets/images/icons/load.gif';
import google from '../../../../assets/images/icons/social-google.svg';
import { getAuth,sendEmailVerification } from "firebase/auth";
import { signoutwihgoogle } from 'index';

function Mailverification() { 

  const auth = getAuth()
  
  const theme= useTheme()
  const fontStyle =  {
    color: "#323259",
}


  return (
    <div  style={{background:theme.palette.primary.light, width: '100%', textAlign:'center'}}>
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 50px)',padding:'0px',height:'100vh' }}>
    <Paper sx={{marginTop:'80px', marginBottom:'80px',width:'50%'}}  >
      <Box sx={{display:'inline'}} >
      
     
     
     
           <img src={image} style={{height:'6em',width:'15em',display:'inline-block', margin:'20px'}}/>

              <Typography sx={{padding:'20px', paddingBottom:'5px', fontSize:'20px', color:fontStyle.color}}> A verification mail has been sent to your registered mail ID. Please verify your MAIL by clicking on the provided URL and reload.
              </Typography>
              <Typography sx={{padding:'20px', paddingTop:'0px', fontSize:'15px', color:fontStyle.color}}>Haven't received the verification link? Click
              
              <button
              style={{
              border:'none',
              backgroundColor:'white',
              color:'#2196f3',
              fontSize:'15px'}}
              onClick={()=>{
                console.log(auth.currentUser)
                sendEmailVerification(auth.currentUser)
              }}>here</button> to resend the link</Typography>
              <Typography sx={{color:fontStyle.color}}>To signout please click here <Button onClick={()=>auth.signOut()}>Signout</Button></Typography>
        </Box>
         </Paper>
       </Grid>
       </div>
  )
}

export default Mailverification