// material-ui
import { Typography, Button,Tooltip, Dialog,DialogContent,DialogActions,DialogTitle, TextField, Rating,Snackbar,Alert,ListItemButton } from '@mui/material';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import NavCollapse from './NavCollapse';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import {useState} from 'react'
import { purple } from "@mui/material/colors";
import styled from "@emotion/styled";
import { addDoc, collection, doc, getDoc,getDocs, onSnapshot, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { auth, db, uid } from "index";
import { useNavigate } from 'react-router';
import DashboardIcon from '@mui/icons-material/Dashboard';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    const [feedback,setFeedback] =useState(false)
    const [Comments,SetComments] = useState('')
    const [Rvalue,setRValue] = useState(1)
    const [Feedsnack,setFeedsnack] = useState(false)

    const navigate = useNavigate()

    const ColorButton = styled(Button)(({ theme }) => ({
      color: theme.palette.getContrastText('#501f84'),
      backgroundColor: '#501f84',
      '&:hover': {
        backgroundColor: '#7030b3',
      },
  }));
  const ColorButton2 = styled(Button)(({ theme }) => ({
      color: theme.palette.getContrastText('#0095ff'),
      backgroundColor:"black",
      '&:hover': { backgroundColor: '#000000bd'}
    }));


    const SubFeedback= async()=>{
     await addDoc(collection(db,"feedbacks"),{
              'Uid':auth.currentUser.uid,
               'name':auth.currentUser.displayName,
              'Rating':Rvalue,
              'Comments':Comments,
              'SubmittedAt':serverTimestamp()
            }).then(()=>{setFeedsnack(true)
                setFeedback(false)
                setRValue(1)
                SetComments('')
             })
    }



    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            case 'Collapse':
                return <NavCollapse key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    







    return <>
    <ListItemButton  onClick={()=>{navigate('/')}} sx={{borderRadius:'12px', paddingLeft:'24px',paddingTop:'10px',paddingBottom:'10px',marginTop:'30px'}}>
    <DashboardIcon sx={{color:"#323259"}}/>
     <Typography sx={{marginLeft: '18px', fontSize: '15px', color: "#323259"}}>Dashboard</Typography>
    </ListItemButton>  
    
    {navItems}
    
    <ListItemButton  href='https://rebolt-2956c.web.app/usage.html' sx={{borderRadius:'12px', paddingLeft:'24px',paddingTop:'10px',paddingBottom:'10px'}}>
    <BookOutlinedIcon sx={{color:"#323259"}}/>
     <Typography sx={{marginLeft: '18px', fontSize: '15px', color: "#323259"}}>User Manual</Typography>
    </ListItemButton>     
    
 
     
     <ListItemButton onClick={()=>setFeedback(true)}  sx={{marginTop:'10px',borderRadius:'12px', paddingLeft:'24px',paddingTop:'10px',paddingBottom:'10px'}}>
     <RateReviewOutlinedIcon sx={{color:"#323259"}}/>
     <Typography sx={{marginLeft: '18px', fontSize: '15px', color: "#323259"}}>Feedback</Typography>
     </ListItemButton>
     


     <Dialog open={feedback} fullWidth>
     <DialogContent  sx={{padding:'0px'}}>
     <DialogTitle sx={{backgroundColor:'white',  color: "#323259", textAlign:'left', fontSize:'0.985em',fontWeight:'600'}}>Feedback</DialogTitle>
       <div style={{padding:'15px',textAlign:'center'}}>
       <Typography component="legend" sx={{paddingBottom:'10px',color: "#323259", fontWeight:'600', fontSize:'0.985em'}}>Rate Us</Typography>
       
       <Rating
        name="simple-controlled"
        value={Rvalue}
        onChange={(e) => {
          setRValue(e.target.value);
        }}
        sx={{fontSize:'40px'}}
      />
      </div>
     
       <TextField type='box' placeholder='Please leave your valuable comment'
        sx={{marginTop:'15px', height:'90%', padding:'15px'}}
          fullWidth 
          multiline
          rows={4}
       />
     </DialogContent>
     <DialogActions>
        <ColorButton onClick={SubFeedback}>Submit</ColorButton>
        <ColorButton2 onClick={()=>setFeedback(false)}>Close</ColorButton2>
     </DialogActions>
         
     </Dialog>
     
     <Snackbar open={Feedsnack} autoHideDuration={2000} onClose={() => setFeedsnack(false)} >
          <Alert onClose={() => setFeedsnack(false)} severity="success" sx={{ width: '100%', backgroundColor: 'green', color: 'white' }}>
            Thanks for Submitting your Feedback
          </Alert>
    </Snackbar>
     
     </>;
};

export default MenuList;
