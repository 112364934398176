
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const queries = {
    id: 'queries',
    type: 'group',
    children: [
        {
            id: 'qeries',
            title: "Faq's",
            type: 'item',
            url: '/Faq/Faq',
            icon: LiveHelpOutlinedIcon,
        }
    ]
};

export default queries;
