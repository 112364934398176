import { Button, Card, CardActions, CardContent, OutlinedInput } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";


const SamplePage = () => (
    <>
<MainCard>
    
</MainCard>
    </>
);

export default SamplePage;
