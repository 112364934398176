
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const collabs = {
    id: 'collabs',
    type: 'group',
    children: [
        {
            id: 'Collabs',
            title: "collaborations",
            type: 'item',
            url: '/collaborations',
            icon: PersonAddAltOutlinedIcon,
        }
    ]
};

export default collabs;
