import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LanIcon from '@mui/icons-material/Lan';
// material-ui
import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery,List,Collapse, Button} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';
import config from 'config';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StarBorder from '@mui/icons-material/StarBorder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TuneIcon from '@mui/icons-material/Tune';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const [open,setOpen] = useState(true)
    const [retroOpen,setretroOpen] = useState(false)
    const [ForwardOpen,setForwardOpen]=  useState(false)
    
    const navigate = useNavigate()
    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => {
        
        return(
        item.id == 'utilities'? <ListItemButton ref={ref} {...props} target={itemTarget}/>
        :<Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />)})
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (

    
    item.sublist ? 
    <>
        <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        disableGutters={true}   
        sx={{
            borderRadius: `${customization.borderRadius}px`,
            mb: 0.5,
            alignItems: 'flex-start',
            backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
            py: level > 1 ? 1 : 1.25,
            pl: `${level * 24}px`
        }}
        onClick={() => {
            setOpen(!open) }}
    >
        <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36}} style={{color:"#323259"}}>{itemIcon}</ListItemIcon>
        <ListItemText style={{marginLeft:'10px',fontSize:'30px'}}
            primary={
                <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} style={{color:"#323259",fontSize:'0.5em'}}>
                    {item.title}
                </Typography>
            }
           
        />
        {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    {open &&

    <>
    <ListItemButton
        disableGutters={true}   
        sx={{
            borderRadius: `${customization.borderRadius}px`,
            mb: 0.5,
            alignItems: 'flex-start',
            backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
            py: level > 1 ? 1 : 1.25,
            pl: `${level * 24}px`,
            marginLeft:'5px'
        }}
        onClick={() => {
            setretroOpen(!retroOpen) }}
    >
        <ListItemIcon  style={{color:"#323259"}}><LanIcon sx={{fontSize:'22px'}}/></ListItemIcon>
        <ListItemText style={{marginLeft:'10px',fontSize:'28px'}}
            primary={
                <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} style={{color:"#323259",fontSize:'0.5em'}}>
                    {item.sublist[0].Title}
                </Typography>
            }
           
        />
        {retroOpen ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
        {
            retroOpen &&
            item.sublist[0].sublist.map((content, index)=>
            <Collapse in={open} key={index+item.title} timeout="auto" unmountOnExit>
        <List component="div" disablePadding >
          <ListItemButton sx={{ pl: 4, borderRadius:'10px', marginBottom:'5px', marginLeft:'5px' }} onClick={()=>{
            itemHandler(content.onclick)
            navigate(content.onclick)}}
            selected ={customization.isOpen.findIndex((id) => id === content.onclick) > -1}
            >
            <ListItemIcon>
              <StarBorder  sx={{color:"#323259",fontSize:'18px'}}/>
            </ListItemIcon>
            <ListItemText color="#323259" primary={
                <Typography sx={{color:"#323259", fontSize:'13px'}}>{content.title}</Typography>} />
          </ListItemButton>
        </List>
      </Collapse>

        )}
    <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        disableGutters={true}   
        sx={{
            borderRadius: `${customization.borderRadius}px`,
            mb: 0.5,
            alignItems: 'flex-start',
            backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
            py: level > 1 ? 1 : 1.25,
            pl: `${level * 24}px`,
            marginLeft:'5px'
        }}
        onClick={() => {
            setForwardOpen(!ForwardOpen) }}
    >
        <ListItemIcon style={{color:"#323259"}}><ArrowForwardIcon sx={{fontSize:'22px'}}/></ListItemIcon>
        <ListItemText style={{marginLeft:'10px',fontSize:'28px'}}
            primary={
                <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} style={{color:"#323259",fontSize:'0.5em'}}>
                    {item.sublist[1].Title}
                </Typography>
            }
           
        />
        {ForwardOpen ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    {
            ForwardOpen &&
            item.sublist[1].sublist.map((content, index)=>
            <Collapse in={open} key={index+item.title} timeout="auto" unmountOnExit>
        <List component="div" disablePadding >
          <ListItemButton sx={{ pl: 4, borderRadius:'10px', marginBottom:'5px', marginLeft:'5px' }} onClick={()=>{
            itemHandler(content.onclick)
            navigate(content.onclick)}}
            selected ={customization.isOpen.findIndex((id) => id === content.onclick) > -1}
            >
            <ListItemIcon>
              <StarBorder  sx={{color:"#323259", fontSize:'18px'}}/>
            </ListItemIcon>
            <ListItemText color="#323259" primary={
                <Typography sx={{color:"#323259", fontSize:'13px'}}>{content.title}</Typography>} />
          </ListItemButton>
        </List>
      </Collapse>

        )}
        <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        disableGutters={true}   
        sx={{
            borderRadius: `${customization.borderRadius}px`,
            mb: 0.5,
            alignItems: 'flex-start',
            backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
            py: level > 1 ? 1 : 1.25,
            pl: `${level * 24}px`,
            marginLeft:'5px'
        }}
        onClick={() => {
            navigate(item.sublist[2].onclick)}}
    >
        <ListItemIcon style={{color:"#323259"}}><TuneIcon  sx={{fontSize:'22px'}}/></ListItemIcon>
        <ListItemText style={{marginLeft:'10px',fontSize:'28px'}}
            primary={
                <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} style={{color:"#323259",fontSize:'0.5em'}}>
                    {item.sublist[2].Title}
                </Typography>
            }
           
        />
    </ListItemButton>
    
    </> }
    </>


       : <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            disableGutters={true}   
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
            selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36}} style={{color:"#323259"}}>{itemIcon}</ListItemIcon>
            <ListItemText style={{marginLeft:'10px',fontSize:'30px'}}
                primary={
                    <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} style={{color:"#323259",fontSize:'0.5em'}}>
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
