
// material-ui
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import imagelogo from '../../../../src/assets/images/icons/rebolt.svg';

// project imports

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => <>
    <Link to={`/Rebolt/Experimentsroot`} style={{textDecoration:'none',display:'flex'}}>
            <img src={imagelogo} style={{height:'3.5em',marginLeft:'2em'}}/>
    </Link>
</>;

export default LogoSection;
