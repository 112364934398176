import rebolt from './rebolt';
import project from './project';
import queries from './queries';
import collabs from './collabs';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [rebolt, project, queries]
};

export default menuItems;
