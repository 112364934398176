import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuthState } from "react-firebase-hooks/auth";

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    Chip,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography
} from '@mui/material';

// project imports
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

//sketcher imports
import { RemoteStructServiceProvider } from 'ketcher-core';

// assets
import { IconBook, IconLogout, IconMail, IconSettings } from '@tabler/icons';

// ==============================|| PROFILE MENU ||============================== //
import { getAuth } from "@firebase/auth";
import { signoutwithgoogle } from 'index';
import { EmailOutlined } from '@mui/icons-material';

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const auth = getAuth();

    const [user, loading, error] = useAuthState(auth);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
    }

    prevOpen.current = open;

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    transition: 'all .2s ease-in-out',
                    border:'none',
                    '& svg':{
                        stroke:'#4a4aa0',
                        fill:'#4a4aa0',
                        height:'0.9375rem'
                    },
                    // '&[aria-controls="menu-list-grow"], &:hover': {
                    //     borderColor: '#4a4aa0',
                    //     backgroundColor: '#323259',
                    //     // color: theme.palette.primary.light,
                    //     '& svg': {
                    //         stroke: '#4a4aa0',
                    //         fill:'#4a4aa0'
                    //     }
                    // },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                        <Avatar
                            sx={{
                                ...theme.typography.mediumAvatar,
                                margin: '8px 0 8px 8px !important',
                                cursor: 'pointer'
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            color="#4a4aa0"
                            src={auth.currentUser?.photoURL}
                        />
                        
                }
                label={<Typography sx={{color:"#222b45",fontSize:'0.9375rem'}}>{user.email}</Typography>}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                            <ClickAwayListener onClickAway={handleClose}>
                                    <Card border={true} sx={{border:'none'}} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <PerfectScrollbar style={{ height: '100%', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 2 }}>
                                            <List
                                                component="nav"
                                                maxHeight="300px"
                                                sx={{
                                                    width: '100%',
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('xl')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                            <ListItemButton
                                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                                selected={selectedIndex === 4}
                                                                onClick={signoutwithgoogle} >
                                                                <ListItemIcon>
                                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={<Typography variant="body2">Sign Out</Typography>} />
                                                            </ListItemButton>
                                                            <ListItemButton
                                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                                selected={selectedIndex === 4}
                                                                onClick={(event) => handleListItemClick(event, 0, '/accountdetails')} >
                                                                <ListItemIcon>
                                                                    <IconSettings stroke={1.5} size="1.3rem" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={<Typography variant="body2">Account settings</Typography>} />
                                                            </ListItemButton>
                                                            <ListItemButton
                                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                                selected={selectedIndex === 4}
                                                                onClick={(event) => handleListItemClick(event, 0, '/accountdetails')} >
                                                                    <ListItemIcon>
                                                                        <IconMail stroke={1.5} size="1.3rem"/>
                                                                    </ListItemIcon>
                                                               
                                                                <ListItemText primary={<Typography variant="body2">{user.email}</Typography>} />
                                                            </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                    </Card>
                            </ClickAwayListener>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
