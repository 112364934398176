import { Typography } from '@mui/material';
import React from 'react';
import './index.scss'
import image  from '../assets/images/icons/load.gif';

export default function Loader(props) {
  return (
    <div className="spinner-container">
    <img src={image} alt="ReBolt" width='300px'/>

    <Typography component='p' variant='p' style={{color:'#222b45'}}>Loading...</Typography>
</div>

  )
}
