// assets
import { LibraryBooks, LibraryBooksOutlined, SaveAlt } from '@mui/icons-material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ShareIcon from '@mui/icons-material/Share';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const project = {
    id: 'projects',
    type: 'group',
    children: [
        {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            url: '/projects',
            icon: AddBoxOutlinedIcon,
            breadcrumbs: true,
        }
    ,
        {
            id: 'Copies',
            title: 'Saved paths',
            type: 'item',
            url: '/Copies',
            icon: SaveAlt,
            breadcrumbs: true
        }
        ,
        // {
        //     id: 'Analogs library',
        //     title: 'Analogs',
        //     type: 'item',
        //     url: '/library',
        //     icon: LibraryBooksOutlined,
        //     breadcrumbs: true,
        //     disabled:true,
        // }
        // , 
        {
            id: 'Shared Experiments',
            title: 'Shared Experiments',
            type: 'item',
            url: '/SharedExperiments',
            icon:  ShareIcon,
            breadcrumbs: true,
            
        },
        {
            id: 'Library',
            title: 'Library',
            type: 'item',
            url: '/SMLibrary',
            icon: LibraryAddOutlinedIcon,
            breadcrumbs: true,   
        }
    ]
};

export default project;
