import { useSelector } from 'react-redux';

//mui imports
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routesmain from 'routes'
import Routesauth from 'authroutes'
// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
// ==============================|| APP ||============================== //


import { initializeApp } from "@firebase/app";
import { getAnalytics } from "@firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Loader from 'loadingscreen';
import Mailverification from 'views/pages/authentication/auth-forms/Mailverification';


//firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDAKQow7DIylH2AlYryqUtoYGZY9wHlizU",
  authDomain: "rebolt-c97b2.firebaseapp.com",
  databaseURL: "https://rebolt-c97b2-default-rtdb.firebaseio.com",
  projectId: "rebolt-c97b2",
  storageBucket: "rebolt-c97b2.appspot.com",
  messagingSenderId: "986328607881",
  appId: "1:986328607881:web:8fec29744ff18c7a1d6a58",
  measurementId: "G-C1LRPK61J1"
};

//initializing firebase app and analytics
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const user = auth.currentUser;


//authentication functions
const provider = new GoogleAuthProvider();

const signinwithgoogle = () => {
    signInWithPopup(auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
        });
}



const signoutwithgoogle = () => {
    auth.signOut();
}


const App = () => {
    const [authstate, setAuthstate] = useState(false);
    const [loading,setloading]=useState(true);
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();


    auth.onAuthStateChanged(user => {
        
        if (user) { setAuthstate(true) } else { setAuthstate(false) }
    })
    console.log(document.readyState)
   
   
   
    useEffect(() => {
      

//   if (document.readyState === "complete") {
        setInterval(() => {
            setloading(false)    
        }, 5000);
 
    // },[document.readyState]);
},[])
    return (
        <>
        {
            loading?
            <Loader/>
            :
            <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                {
                    authstate ?
                    (auth?.currentUser?.emailVerified?
                     <NavigationScroll><Routesmain /> </NavigationScroll>:
                        <NavigationScroll><Mailverification/></NavigationScroll>
                     )
                       : <NavigationScroll>
                            <Routesauth />
                        </NavigationScroll>
                }
            </ThemeProvider>
        </StyledEngineProvider>
        }
            
        </>
    );
};

export default App;